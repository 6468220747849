import React, { ComponentProps, DispatchWithoutAction } from "react";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import ConfirmationStepWrapper, { IConfirmationStepWrapperProps } from "./ConfirmationStepWrapper";

export interface IExperimentConfirmationStepWrapperProps {
  /** Dispatches that the appointment has been successfully booked. */
  recordBookedAppointment: DispatchWithoutAction;
}

/**
 * Bind both the {@link IExperimentConfirmationStepWrapperProps.recordBookedAppointment}
 * and {@link IConfirmationStepWrapperProps.onConfirmedAppointment} callbacks together.
 */
const ExperimentConfirmationStepWrapper: React.FC<
  ComponentProps<typeof ConfirmationStepWrapper> & IExperimentConfirmationStepWrapperProps
> = ({ onConfirmedAppointment, recordBookedAppointment: reportConfirmedAppointment, ...rest }) => {
  const handleConfirmedAppointment = (customerNoteText: string) => {
    onConfirmedAppointment(customerNoteText);
    reportConfirmedAppointment();
  };

  return <ConfirmationStepWrapper {...rest} onConfirmedAppointment={handleConfirmedAppointment} />;
};

export default ExperimentConfirmationStepWrapper;
