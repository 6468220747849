import { Nullable } from "sonobello.utilities.react";

import { IFlow } from "../../Types/IFlow";
import { IExperimentConfiguration } from "./ExperimentConfiguration";
import ExperimentKey from "./ExperimentKey";
import IExperimentContextProps from "./IExperimentContextProps";
import IFlowResolver, { IFlowAssignment } from "./IFlowResolver";

/**
 * An {@link IFlowResolver} which returns the configured experimental flow if the input experiment matches the configured
 * constraints and the experiment context successfully assigns that experience. Throws otherwise.
 */
abstract class BaseExperimentalFlowResolver implements IFlowResolver {
  private readonly _experimentActiveFlow: IFlow;
  private readonly _experimentInactiveFlow: IFlow;
  private readonly _experimentKey: ExperimentKey;

  /**
   * @param experimentActiveFlow - The flow to assign if the resolver matches the flow name, and the experiment is active.
   * @param experimentInactiveFlow - The flow to assign if the resolver matches the flow name, and the experiment is inactive.
   * @param experimentKey - The key of the experiment to evaluate for assignment to the experimental flow.
   */
  constructor(experimentActiveFlow: IFlow, experimentInactiveFlow: IFlow, experimentKey: ExperimentKey) {
    this._experimentActiveFlow = experimentActiveFlow;
    this._experimentInactiveFlow = experimentInactiveFlow;
    this._experimentKey = experimentKey;
  }

  readonly invoke = (experimentContext: IExperimentContextProps, sessionFlowName: string): IFlowAssignment => {
    const isExperimentNameExactMatch = sessionFlowName === this._experimentActiveFlow.name;
    if (isExperimentNameExactMatch || sessionFlowName === this._experimentInactiveFlow.name) {
      let experimentConfig: Nullable<IExperimentConfiguration> = null;
      try {
        experimentConfig = experimentContext.getExperimentConfiguration(this._experimentKey);
        return {
          flow:
            experimentConfig.isActive || isExperimentNameExactMatch
              ? this._experimentActiveFlow
              : this._experimentInactiveFlow,
          experimentConfiguration: isExperimentNameExactMatch
            ? { experimentId: experimentConfig.experimentId, isActive: true }
            : experimentConfig
        };
      } catch (e) {
        console.error(e, e);
        console.warn(`Failed to retrieve feature flags for experiment ${this._experimentKey}.`);
        throw new Error(BaseExperimentalFlowResolver.Messages.assignmentDecisionThrew);
      }
    }
    throw new Error(BaseExperimentalFlowResolver.Messages.flowNotResolved);
  };

  static readonly Messages = {
    flowNotResolved: "Flow could not be resolved.",
    assignmentDecisionThrew:
      "An error occurred while attempting to determine assignment status of the experimental flow."
  };
}

export default BaseExperimentalFlowResolver;
