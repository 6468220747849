import "./index.css";
import "./sentry_init";

import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import App from "./App";
import { LocalStorageConfigs, loadFromLocal } from "./utils/LocalStorage";
import singleProcessReleaseReservation from "./V2/App/Actions/ReleaseReservation";

const container = document.getElementById("root");

// Create a root.
if (!container) throw "Root could not be found.";
const root = createRoot(container);

// Initial render
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

// if the user has a reservation and the environment is not local display a confirmation alert on page close
window.addEventListener("beforeunload", ev => {
  if (
    (loadFromLocal<unknown>(LocalStorageConfigs.legacyReservation.key) ||
      loadFromLocal<unknown>(LocalStorageConfigs.opsReservation.key)) &&
    process.env.REACT_APP_ENVNAME !== "Local"
  ) {
    ev.preventDefault();
    ev.returnValue = "Are you sure you want to exit? Your current reservation will be lost.";
    return "Are you sure you want to exit? Your current reservation will be lost.";
  }
});

window.addEventListener("unload", singleProcessReleaseReservation);
