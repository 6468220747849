import React, { ComponentProps } from "react";

import AppContextValueProvider from "../../../App/Compositions/AppContextValueProvider";
import ValidationConfirmationStepWrapper, {
  IValidationConfirmationStepWrapperConfig
} from "../Components/ValidationConfirmationStepWrapper";
import ContextExperimentConfirmationStepWrapper from "./ContextExperimentConfirmationStepWrapper";

const ConfiguredValidationConfirmationStepWrapper: React.FC<
  Omit<ComponentProps<typeof ValidationConfirmationStepWrapper>, keyof IValidationConfirmationStepWrapperConfig>
> = props => (
  <ValidationConfirmationStepWrapper ConfirmationStepWrapper={ContextExperimentConfirmationStepWrapper} {...props} />
);

/** A composed {@link ConfirmationStepWrapper} with partial props provided by the App Context. */
const AppContextValidationConfirmationStepWrapper = AppContextValueProvider<
  Pick<ComponentProps<typeof ConfiguredValidationConfirmationStepWrapper>, "reservation" | "sessionKey">,
  ComponentProps<typeof ConfiguredValidationConfirmationStepWrapper>
>({
  Component: ConfiguredValidationConfirmationStepWrapper,
  map: ({ reservation, sessionKeyCState }) => ({ reservation, sessionKey: sessionKeyCState })
});

export default AppContextValidationConfirmationStepWrapper;
