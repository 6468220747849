import EnvironmentConfiguration from "../../../constants/EnvironmentConfiguration";
import ApiRequestPaths from "../../Constants/ApiRequestPaths";
import { ReservationRequestWithDistance } from "../../Steps/Scheduling/Hooks/UseCreateOpsDistanceReservation";
import { CachedSession } from "../../Types/ISession";
import OpsReservation from "../../Types/OpsReservation";

/** Attempt to load the held reservation from the browser cache, and if successful then release it.
 * @remarks Can't use useApi or axios here since this function is intended to execute all actions in a single
 * execution instance.
 */
export const singleProcessReleaseReservation = () => {
  try {
    const { reservation, token, leadId, customer } = new CachedSession();

    if (reservation) {
      const requestInit: RequestInit = {
        keepalive: true,
        method: "POST",
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${token.token}` },
        body: JSON.stringify(
          new ReservationRequestWithDistance(reservation.slot, undefined, reservation.center.distance)
        )
      };
      if (reservation instanceof OpsReservation) {
        window
          .fetch(
            `${EnvironmentConfiguration.baseUrl}/${ApiRequestPaths.postOpsReservationUrlV1_1(
              leadId,
              customer?.id,
              reservation.center.id,
              reservation.service.id,
              reservation.slot.id
            )}`,
            requestInit
          )
          .catch(() => {
            // NOOP
          });
      } else
        window
          .fetch(
            `${EnvironmentConfiguration.baseUrl}/${ApiRequestPaths.postReleaseLegacyReservationUrl(
              leadId,
              reservation.center.id,
              reservation.service.id
            )}`,
            requestInit
          )
          .catch(() => {
            // NOOP
          });
    }
  } catch {
    // NOOP
  }
};

export default singleProcessReleaseReservation;
