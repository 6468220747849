import { Nullable } from "sonobello.utilities.react";

import IBookableCenter, { BookableCenter } from "../../Types/IBookableCenter";
import ICenter, { Center } from "../../Types/ICenter";
import ICalendar from "./ICalendar";

/** A record for the current static calendar tracking for a center. */
interface ITrackedCalendarHubCenter {
  /** The most-recent state of the center's calendar. */
  calendar: Nullable<ICalendar>;
  /** The center that is being tracked. */
  center: ICenter;
  /** The center being tracked with its offered bookable services. */
  bookableCenter: Nullable<IBookableCenter>;
  /** Flag indicating if the center's calendar can possible by booked.
   * @remarks Booking is not possible for the tracked center if the calendar has confirmed 0 availability, or if
   * it failed to be retrieved from the server.
   */
  isBookingPossible: boolean;
}

/** {@inheritdoc ITrackedCalendarHubCenter} */
export class TrackedCalendarHubCenter implements ITrackedCalendarHubCenter {
  calendar: Nullable<ICalendar>;
  center: ICenter;
  bookableCenter: Nullable<IBookableCenter>;
  isBookingPossible: boolean;

  constructor(center: ICenter) {
    this.center = center;
    this.calendar = null;
    this.isBookingPossible = true;
    this.bookableCenter = null;
  }
}

/** The application authority for all centers that might be bookable for an appointment, and the calendar state for
 * each.
 */
class CalendarHub {
  private trackedCenters: ITrackedCalendarHubCenter[];

  /** The calendars available for booking. Only not-null when all tracked centers have resolved calendars or have errored. */
  readonly bookableCenters: Nullable<IBookableCenter[]>;
  /** A flag indicating if booking is possible for the session.
   * @remarks Booking is not possible if all tracked centers have either confirmed 0 availability, or failed to
   * load their calendars.
   */
  readonly isBookingPossible: boolean;
  /** The centers for which workers should be monitoring calendar updates. */
  readonly workerCenters: ICenter[];

  constructor(trackedCenters: ITrackedCalendarHubCenter[], isBookingPossible?: boolean) {
    this.trackedCenters = trackedCenters;
    this.isBookingPossible =
      isBookingPossible === false
        ? false
        : this.trackedCenters.some(
            c => c.isBookingPossible && (c.calendar === null || c.calendar.schedules.some(s => s.isAnySlotAvailable))
          );
    this.bookableCenters =
      this.trackedCenters.length && this.trackedCenters.every(c => c.calendar !== null || !c.isBookingPossible)
        ? this.trackedCenters
            .filter(c => c.bookableCenter && c.isBookingPossible)
            .map(c => c.bookableCenter!)
            .sort(Center.shortestDistanceComparator)
        : null;
    if (this.bookableCenters && !this.bookableCenters.length) {
      this.bookableCenters = null;
      this.isBookingPossible = false;
    }
    this.workerCenters =
      isBookingPossible !== false ? this.trackedCenters.filter(c => c.isBookingPossible).map(c => c.center) : [];
  }

  /** Get the calendar for the center with the given id.
   * @param centerId - The id of the center which owns the calendar to be retrieved.
   */
  readonly getCalendar = (centerId: string): Nullable<ICalendar> => {
    const centerSchedule = this.trackedCenters.find(s => s.center.id === centerId);
    return centerSchedule?.calendar || null;
  };

  /** Remove a center from tracking in the hub.
   * @remarks Usually this is because we have confirmed booking isn't possible for the center, or else there are
   * errors in obtaining its calendar.
   * @param centerId - The id of the center to be removed from the hub.
   * @returns A new calendar hub with the center removed.
   */
  readonly removeCenter = (centerId: string): CalendarHub => {
    const newTrackedCenters = [...this.trackedCenters.map(c => ({ ...c }))];
    const index = newTrackedCenters.findIndex(t => t.center.id === centerId);
    if (index === -1) throw "Failed to find a center to remove.";
    newTrackedCenters[index].isBookingPossible = false;
    return new CalendarHub(newTrackedCenters);
  };

  /** Update the calendar for the center with the given id.
   * @param centerId - The id of the center to be removed from the hub.
   * @param calendar - The new calendar to be set for the center.
   * @returns A new calendar hub with calendar for the specified center updated.
   */
  readonly update = (centerId: string, calendar: ICalendar): CalendarHub => {
    const newTrackedCenters = [...this.trackedCenters.map(c => ({ ...c }))];
    const index = newTrackedCenters.findIndex(t => t.center.id === centerId);
    if (index === -1) throw "Failed to find a center to update.";
    newTrackedCenters[index].calendar = calendar;
    newTrackedCenters[index].bookableCenter = new BookableCenter(
      newTrackedCenters[index].center,
      calendar.schedules.filter(s => s.isAnySlotAvailable).map(s => s.service)
    );

    newTrackedCenters[index].isBookingPossible = calendar.schedules.some(s => s.isAnySlotAvailable);
    return new CalendarHub(newTrackedCenters);
  };

  /** Forcibly set the calendar hub status to declare that booking for the session is no longer bookable. */
  readonly setUnbookable = (): CalendarHub => new CalendarHub(this.trackedCenters, false);

  /** Get the centers which are being tracked for potential booking. */
  readonly getTrackedCenters = (): ICenter[] => this.trackedCenters.map(c => c.center);
}

export default CalendarHub;
