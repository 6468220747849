import { useEffect, useState } from "react";
import { Hook, Nullable } from "sonobello.utilities.react";

import useObx from "../../../utils/UseObx";
import ApiRequestPaths from "../../Constants/ApiRequestPaths";
import IBookingCenterOptionResponse, { ResponseBookingCenterOption } from "../../Types/IBookingCenterOptionResponse";
import ICenter from "../../Types/ICenter";

export interface UseGetBookingCenterOptionsProps {
  /** The customer's postal code to use as the origin for the center lookup. */
  customerPostalCode: Nullable<string>;
  /** The postal code of the center to which the customer is assigned to be used as a fallback lookup origin. */
  centerPostalCode: Nullable<string>;
  /** The id of the center to which the customer is assigned. */
  centerId: string;
}

/** A hook that obtains the list of centers in which the application can book appointments if a calendar can be resolved. */
const UseGetBookingCenterOptions: Hook<ICenter[], boolean, UseGetBookingCenterOptionsProps> = ({
  customerPostalCode,
  centerId,
  centerPostalCode
}) => {
  const postalCodeOrigin = customerPostalCode || centerPostalCode;
  if (!postalCodeOrigin) throw new Error("At least one of customerPostalCode or centerPostalCode must be provided.");
  const { res, err, loading, setReq } = useObx<IBookingCenterOptionResponse[]>("Get Bookable Centers");

  const [result, setResult] = useState<Nullable<ICenter[]>>(() => {
    setReq(
      r =>
        r && {
          ...r,
          url: ApiRequestPaths.getCentersV3(postalCodeOrigin, centerId)
        }
    );
    return null;
  });

  useEffect(() => {
    res && setResult(res.payload.map(c => new ResponseBookingCenterOption(c)));
  }, [res]);

  return { result, error: Boolean(err), isLoading: loading };
};

export default UseGetBookingCenterOptions;
