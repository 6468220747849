import React, { useEffect } from "react";
import { IComponentParent, Nullable } from "sonobello.utilities.react";

import {
  IExpiredReservationViewRouter,
  ISomethingWentWrongRouter,
  IThankYouViewRouter
} from "../../../Routing/Types/IRouter";
import IReservation from "../../../Types/IReservation";
import { IStepWrapperProps } from "../../Components/StepWrapper";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { IConfirmationStepWrapperConfig, IConfirmationStepWrapperProps } from "./ConfirmationStepWrapper";

type IAppContextConfirmationStepWrapper = IComponentParent &
  Pick<IStepWrapperProps, "NextButtonDesktop" | "NextButtonMobile"> &
  Pick<IConfirmationStepWrapperConfig, "router">;

export interface IValidationConfirmationStepWrapperProps
  extends IAppContextConfirmationStepWrapper,
    IConfirmationStepWrapperConfig {
  reservation: Nullable<IReservation>;
  router: IExpiredReservationViewRouter & IThankYouViewRouter & ISomethingWentWrongRouter;
  sessionKey: string;
}

export interface IValidationConfirmationStepWrapperConfig {
  ConfirmationStepWrapper: React.FC<
    IAppContextConfirmationStepWrapper &
      IConfirmationStepWrapperConfig &
      Pick<IConfirmationStepWrapperProps, "reservation">
  >;
}

/**
 * Validates that the reservation exists before rendering the {@link ConfirmationStepWrapper}, otherwise sends the user
 * to the expired reservation view.
 */
const ValidationConfirmationStepWrapper: React.FC<
  IValidationConfirmationStepWrapperProps & IValidationConfirmationStepWrapperConfig
> = ({ reservation, router, sessionKey, ConfirmationStepWrapper, ...rest }) => {
  useEffect(() => {
    if (!reservation) {
      router.goToExpiredReservationView({ sessionKey });
    }
  }, [reservation, router]);
  return reservation ? <ConfirmationStepWrapper {...rest} router={router} reservation={reservation} /> : null;
};

export default ValidationConfirmationStepWrapper;
